import { BiLogoNodejs } from "react-icons/bi";
import { IoLogoElectron } from "react-icons/io5";
import { FaHtml5 } from "react-icons/fa";
import { SiExpress } from "react-icons/si";
import { TbApi } from "react-icons/tb";
import { IoLogoJavascript } from "react-icons/io5";
import { FaCss3Alt } from "react-icons/fa";
import { FaRegFileCode } from "react-icons/fa";
import { FaFileAudio } from "react-icons/fa";
import { SiTypescript } from "react-icons/si";
import { SiCheerio } from "react-icons/si";
import { FaVuejs } from "react-icons/fa";
import { FaFigma } from "react-icons/fa6";
import { SiTestinglibrary } from "react-icons/si";
import { SiCypress } from "react-icons/si";
import { SiAmazonsimpleemailservice } from "react-icons/si";
import { SiMongodb } from "react-icons/si";
import { MdScheduleSend } from "react-icons/md";
import { SiReact } from "react-icons/si";
import { RiTailwindCssFill } from "react-icons/ri";

export const techMap = {
    node: {
        Icon: <BiLogoNodejs fill={"white"} size={"20px"} />,
        color: "#417E38",
        name: "Node JS",
    },
    electron: {
        Icon: <IoLogoElectron fill={"#9feaf9"} size={"20px"} />,
        color: "#1B1C26",
        name: "Electron JS",
    },
    html: {
        Icon: <FaHtml5 fill={"#f16a32"} size={"20px"} />,
        color: "#323437",
        name: "HTML 5",
    },
    react: {
        Icon: <SiReact fill={"#5ec6dd"} size={"20px"} />,
        color: "#323437",
        name: "React JS",
    },
    express: {
        Icon: <SiExpress fill={"#f3e02a"} size={"20px"} />,
        color: "#323437",
        name: "Express JS",
    },
    "API Integration": {
        Icon: <TbApi fill={"#fff"} size={"20px"} />,
        color: "#598011",
        name: "API Integration",
    },
    "web audio API": {
        Icon: <FaFileAudio fill={"#fff"} size={"20px"} />,
        color: "#801175",
        name: "Web Audio API",
    },
    "file encoding": {
        Icon: <FaRegFileCode fill={"#fff"} size={"20px"} />,
        color: "#798011",
        name: "File Encoding",
    },
    js: {
        Icon: <IoLogoJavascript fill={"#f3e02a"} size={"20px"} />,
        color: "#323437",
        name: "Javascript",
    },
    css: {
        Icon: <FaCss3Alt fill={"#3053e5"} size={"20px"} />,
        color: "#323437",
        name: "CSS 3",
    },
    tailwind: {
        Icon: <RiTailwindCssFill fill={"#25b4ba"} size={"20px"} />,
        color: "#323437",
        name: "Tailwind",
    },
    typescript: {
        Icon: <SiTypescript fill={"#5e98d5"} size={"20px"} />,
        color: "#323437",
        name: "Typescript",
    },
    vue: {
        Icon: <FaVuejs fill={"#33bb87"} size={"20px"} />,
        color: "#323437",
        name: "Vue JS",
    },
    cheerio: {
        Icon: <SiCheerio fill={"#fff"} size={"20px"} />,
        color: "#e9902b",
        name: "Cheerio JS",
    },
    "react testing library": {
        Icon: <SiTestinglibrary fill={"#fb4d4d"} size={"20px"} />,
        color: "#323437",
        name: "React Testing Library",
    },
    cypress: {
        Icon: <SiCypress fill={"#62d2a4"} size={"20px"} />,
        color: "#323437",
        name: "E2E testing with Cypress",
    },
    "UI/UX": {
        Icon: <FaFigma fill={"#fff"} size={"20px"} />,
        color: "#cfc511",
        name: "UX & UI in Figma",
    },
    nodemailer: {
        Icon: <SiAmazonsimpleemailservice fill={"#fff"} size={"20px"} />,
        color: "#17855e",
        name: "Nodemailer Integration",
    },
    mongodb: {
        Icon: <SiMongodb fill={"#5eb14f"} size={"20px"} />,
        color: "#323437",
        name: "Mongo DB via Mongoose",
    },
    "node-cron": {
        Icon: <MdScheduleSend fill={"white"} size={"20px"} />,
        color: "#541785",
        name: "Automated job by Node Cron",
    },
};
