"use client";
import React from "react";

import Laptop from "../_components/Laptop.js";
import NavMenu from "../_components/NavMenu.js";
import Footer from "../_components/Footer.js";
import FadeIn from "../_wrappers/FadeIn.js";

import phoneHero from "../_images/phone_hero.png";
import techHero from "../_images/tech_hero.png";
import ProjectTable from "../_components/ProjectTable.js";
import useIsOnScreen from "../_wrappers/useIsOnScreen.js";

import { PiFigmaLogoThin } from "react-icons/pi";
import { VscVscode } from "react-icons/vsc";
import { MdRocketLaunch } from "react-icons/md";
import { SiJest } from "react-icons/si";

export default function Home(props) {
    const paragraphtTwoRef = React.useRef();
    const parargraphTwoOnScreen = useIsOnScreen(paragraphtTwoRef);
    const paragraphtThreeRef = React.useRef();
    const parargraphThreeOnScreen = useIsOnScreen(paragraphtThreeRef);

    return (
        <main>
            <section className="flex lg:min-h-screen flex-col items-center justify-between p-2 lg:p-24">
                <nav className="w-full">
                    <div className="flex mx-auto max-w-5xl">
                        <NavMenu {...props} />
                    </div>
                </nav>
                <Laptop>
                    <div className="w-full text-center py-6">
                        <FadeIn>
                            <h1 className="text-slate-400 text-2xl py-3 mt-4"> Hi, I'm Rory</h1>
                            <h1 className="text-green-500 text-5xl font-semibold py-3 mt-5"> Web & UI Developer</h1>
                            <h1 className="lg:text-slate-200 text-2xl py-3"> Frontend | Backend | UI Developer</h1>
                            <div className="mt-12">
                                <a href="#projects" className="btn reflect nuke text-black shadow-emerald-500/40 whitespace-nowrap">
                                    View Projects
                                </a>
                            </div>
                        </FadeIn>
                    </div>
                </Laptop>
            </section>

            <section className="flex h-auto my-12 flex-col items-center justify-between overflow-hidden">
                <div className="my-[12rem] shadow-lg shadow-[#74cb9262] bg-white p-12 pb-32 md:p-24 flex w-full mr-[10vw] pl-[20vw] border-radius">
                    <div className="flex max-w-5xl md:ml-auto md:mr-[8%]">
                        <article className="flex flex-col md:flex-row items-center gap-12">
                            <div className="md:w-2/3 md:order-1 order-2 md:pl-12 md:min-w-[400px]" ref={paragraphtThreeRef}>
                                {parargraphThreeOnScreen && (
                                    <FadeIn>
                                        <h2 className="font-semibold text-3xl md:text-6xl pt-4">
                                            More than half a decade of<br></br> <span className="text-green-500">Experience</span>
                                        </h2>
                                    </FadeIn>
                                )}

                                <p className="mt-8 leading-loose w-full">
                                    The best code bases that I have worked with, are clear and written for any level developer to work in.
                                    Others become better as a style guide is established over time. I have a vast amount of experience in
                                    both types.
                                </p>

                                <div className="mt-12 w-full flex ">
                                    <a href="#projects" className="text-center btn w-full reflect nuke text-black shadow-emerald-500/40 ">
                                        View Projects
                                    </a>
                                </div>
                            </div>
                            <div className="md:w-2/8 md:pl-8 order-1 md:order-2  ">
                                <img src={phoneHero} alt={"over a decade of experience in Mobile First Design"} />
                            </div>
                        </article>
                    </div>
                </div>
            </section>
            <section className="flex my-32 h-auto flex-col items-center justify-between">
                <div className="max-w-5xl text-center flex flex-col items-center mx-auto">
                    <h2 className="font-semibold text-3xl md:text-6xl pt-4 items-center">
                        Collaborate <span className="text-green-500">Ideas</span>.
                    </h2>
                    <PiFigmaLogoThin className="my-6" size={50} />
                    <div style={{ width: 4, height: 50, margin: "12px 0", background: "#74cb9262" }}></div>
                    <h2 className="font-semibold text-3xl md:text-6xl pt-4 items-center flex flex-col md:flex-row">
                        <VscVscode className="mx-6" size={50} />
                        Build & Test <span className="text-green-500"> &nbsp;Prototypes</span>.
                        <SiJest className="mx-6" size={50} />
                    </h2>
                    <div style={{ width: 4, height: 70, margin: "12px 0", background: "#74cb9262" }}></div>
                    <MdRocketLaunch className="my-6" size={50} />
                    <h2 className="font-semibold text-2xl md:text-6xl pt-4 items-center">
                        Project<span className="text-green-500"> Deployment</span>.
                    </h2>
                </div>
            </section>
            <section className="flex my-0 flex-col items-center justify-between overflow-hidden">
                <div className="my-[12rem] shadow-lg shadow-[#74cb9262] bg-white p-12 pb-32 md:p-24  flex w-full md:ml-[10vw] md:pr-[20vw] border-radius">
                    <div className="max-w-5xl mx-auto">
                        <article className="flex flex-col md:flex-row items-center gap-12 " ref={paragraphtTwoRef}>
                            <div className="w-2/3 md:pl-8">
                                <img
                                    style={{ width: `900px`, height: "auto" }}
                                    src={techHero}
                                    alt="Modern tech stack knowledge in both frontend and backend"
                                />
                            </div>
                            <div className="md:w-2/3 flex flex-col md:pl-12">
                                {parargraphTwoOnScreen && (
                                    <FadeIn>
                                        <h2 className="font-semibold text-3xl md:text-6xl pt-4">
                                            Modern tech stack<br></br> <span className="text-green-500">knowledge</span>.
                                        </h2>
                                    </FadeIn>
                                )}

                                <p className=" mt-8 leading-loose">
                                    I try to keep it simple when possible, but using the latest tech is sometimes the best solution. Often
                                    it’s better to make a compromise between both. In these scenarios, I have gained valuable experience in
                                    technical choices that have long term impacts.
                                </p>

                                <div className="mt-12 w-full flex ">
                                    <a
                                        target="__blank"
                                        rel="noopener noreferrer"
                                        href="https://github.com/RL4444"
                                        className="text-center btn w-full reflect nuke text-black shadow-emerald-500/40 "
                                    >
                                        View Github
                                    </a>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </section>
            <section id="projects" className="flex  h-auto flex-col items-center justify-between">
                <div className="max-w-5xl text-center mx-auto">
                    <h2 className="font-semibold text-3xl md:text-6xl pt-4">
                        Sample <span className="text-green-500">Projects</span>.
                    </h2>
                    <p className="mt-4">Browse projects & their code.</p>
                    <ProjectTable />
                </div>
            </section>
            <section className="flex py-32 h-auto items-center justify-between w-full overflow-hidden">
                <div className="text-center mx-auto">
                    <div className="relative flex flex-col gap-12 md:flex-row items-center justify-center w-100">
                        <div className="md:w-[50vw] flex justify-end">
                            <h2 className="font-semibold text-3xl md:text-5xl pt-4">
                                Let's Build <br className="hidden md:block" />
                                <span className="text-green-500">Something</span>.
                            </h2>
                        </div>
                        <div className="bg-white flex flex-col items-start p-4 sm:p-12 shadow-lg shadow-[#74cb9262] border-radius">
                            <div className="flex flex-row items-center md:w-[50vw]">
                                <div className="bg-primary text-white flex items-center justify-center h-[60px] w-[60px] overflow-hidden rounded-full text-xl">
                                    R
                                </div>
                                <div className="ml-4 text-left">
                                    <h2 className="text-xl sm:whitespace-nowrap text-black">Fullstack developer</h2>
                                    <p className="text-slate-400 sm:whitespace-nowrap">berlin based and remote</p>
                                </div>
                            </div>

                            <h3 className="text-lg md:text-3xl text-slate-400 mt-6">roryldeveloper[at]gmail.com</h3>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </main>
    );
}
