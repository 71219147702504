import { FaGithub } from "react-icons/fa";
import { LuMailQuestion } from "react-icons/lu";

export default () => {
    return (
        <div className="black-bg p-24 flex flex-col items-center gap-12">
            <h2 className="font-semibold text-white text-3xl md:text-5xl pt-4 sm:whitespace-nowrap">
                Questions? <span className=" text-primary"> Get in touch</span>.
            </h2>
            <h3 className="text-xl md:text-3xl text-white break-words">
                roryldeveloper<span>[at]</span>gmail.com
            </h3>
            <div className="flex gap-8">
                <a target="_blank" rel="noopener noreferrer" href="https://github.com/RL4444">
                    <FaGithub size={35} color={"#22b41f"} />
                </a>
                <a target="_blank" rel="noopener noreferrer" href="mailto:roryldeveloper@gmail.com">
                    <LuMailQuestion size={35} color={"#22b41f"} />
                </a>
            </div>
        </div>
    );
};
