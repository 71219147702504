const ProjectCard = ({ project, onClick, cssId }) => {
    return (
        <div id={cssId} onClick={onClick} className="bg-black max-w-[300px] project-card-wrapper mt-2 overflow-hidden cursor-pointer">
            <div className={`my-auto object-cover ${project.paddedCard ? "p-20 md:p-8" : ""}`}>
                <img src={project.imgSrc} alt={project.title + " cover"} />
            </div>
            <div className="bg-white py-3 px-1 mt-auto h-[70px] overflow-hidden">
                <h5 className="font-thin uppercase line-clamp-2  text-ellipsis barlow">{project.title}</h5>
            </div>
        </div>
    );
};

export default ProjectCard;
