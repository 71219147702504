import { techMap } from "../utils";
import { IoIosClose } from "react-icons/io";

const GalleryCard = ({ project, cssId, closeModal }) => {
    return (
        <>
            <div onClick={closeModal} className="fixed top-2 right-2">
                <button className="bg-black rounded-full overflow-hidden flex justify-center items-center">
                    <IoIosClose fill="#fff" size="25px" />
                </button>
            </div>
            <div id={cssId} className="flex flex-col bg-zinc-800 duration-150 h-[100dvh] py-3">
                <div className="bg-black overflow-hidden max-h-2/3 flex justify-center">
                    {/* image */}
                    <img
                        className={`${project.paddedCard ? "p-12 md:p-24" : ""} object-contain`}
                        src={project.imgSrc}
                        alt={project.title}
                    />
                </div>
                <div className="bg-white pb-4 flex flex-col flex-auto">
                    <div className="px-4 pt-4 md:px-8 bg-white">
                        <h5 className="text-green-700 text-left font-normal text-3xl md:text-4xl py-1 barlow">{project.title}</h5>
                    </div>
                    <div className="my-1 flex flex-wrap gap-2 mt-1 p-4 md:px-8">
                        {project.tags.map((eachTag) => {
                            const bgColor = techMap[eachTag].color;
                            const tagName = techMap[eachTag].name;
                            const TagIcon = techMap[eachTag].Icon;

                            return (
                                <div
                                    key={eachTag}
                                    className="rounded-3xl py-1 px-4 flex items-center gap-2 shadow-2xl shadow-black"
                                    style={{ background: bgColor }}
                                >
                                    <span className="text-white font-thin text-xs md:text-sm">{tagName}</span>
                                    {TagIcon}
                                </div>
                            );
                        })}
                    </div>
                    <div className="flex justify-end mt-auto p-4 md:px-8 gap-2">
                        <div className="mt-auto  pt-3">
                            <a
                                rel="noreferrer noopener"
                                target="_blank"
                                href={project.githubUrl}
                                className="btn !text-xs nuke text-black shadow-emerald-500/40 whitespace-nowrap"
                            >
                                View {"</>"} on Github
                            </a>
                        </div>
                        {project.liveDemoUrl && (
                            <div className="mt-auto  pt-3">
                                <a
                                    rel="noreferrer noopener"
                                    target="_blank"
                                    href={project.liveDemoUrl}
                                    className="btn !text-xs red text-black shadow-emerald-500/40 whitespace-nowrap"
                                >
                                    Live Demo
                                </a>
                            </div>
                        )}
                    </div>
                    <br />
                    <br />
                </div>
            </div>
        </>
    );
};

const ProjectOverlayGallery = ({ project, closeModal, currentImageId }) => {
    return (
        <div className="fixed inset-0 flex justify-center h-[100dvh] overflow-auto z-40">
            <div className="max-w-screen-sm flex flex-col absolute z-50">
                <GalleryCard closeModal={closeModal} project={project} cssId={currentImageId} />
            </div>
            <div
                id="project-gallery-bg-el"
                onClick={closeModal}
                className="bg-zinc-900/40 fixed inset-0  h-[100dvh] overflow-auto z-30"
            ></div>
        </div>
    );
};

export default ProjectOverlayGallery;
